<template>
  <div>
    <b-row
      v-if="$router.currentRoute.name == 'admin-settings-dashboard'"
      class="mb-2"
    >
      <b-col
        cols="6"
      >
        <h2
          class="mr-2 mb-0"
        >
          Settings Dashboard
        </h2>
      </b-col>
      <b-col
        cols="6"
      >
        <div
          class="d-flex justify-content-end"
        >
          <b-button
            class="ml-1"
            variant="primary"
            :disabled="isLoading"
            @click="fetchWidgetCards"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            <feather-icon
              v-else
              icon="RefreshCcwIcon"
              size="15"
            />
            <span
              class="ml-25 align-middle"
            >Refresh</span>
          </b-button>

          <b-dropdown
            class="ml-2"
            toggle-class="p-0"
            variant="primary"
            dropleft
            no-caret
            right
          >
            <template
              #button-content
            >
              <feather-icon
                icon="GridIcon"
                size="21"
                class="m-50"
              />
            </template>
            <b-dropdown-item
              @click="showCardWidgetsModal('create')"
            >
              Create Widget
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.cards-visibility-modal
            >
              Visible Widgets
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mb-2"
    >
      <b-col
        cols="6"
      >
        <h2>
          Good Day, {{ userData.fullName }}!
        </h2>
        <p>Here's what's happening with {{ currentSchoolName }} today.</p>
      </b-col>
      <b-col
        cols="6"
      >
        <div
          class="d-flex justify-content-end"
        >
          <b-button
            class="ml-1"
            variant="primary"
            :disabled="isLoading"
            @click="fetchCachedWidgetCards"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            <feather-icon
              v-else
              icon="RefreshCcwIcon"
              size="15"
            />
            <span
              class="ml-25 align-middle"
            >Refresh</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row
      v-if="isLoading"
      align-h="center"
    >
      <TableSpinner />
    </b-row>

    <b-row v-else>
      <draggable
        :list="widgetCards"
        tag="slot"
        @end="finishMove"
      >
        <template
          v-for="(card, index) in widgetCards"
        >
          <b-col
            :key="index"
            :cols="colsNumber(card)"
            class="mb-2"
          >
            <card-total
              v-if="card.type == 'Total' && card.visible"
              :card="card"
            />

            <card-goal
              v-if="card.type == 'Goal' && card.visible"
              :card="card"
            />

            <card-pie
              v-if="card.type == 'Pie' && card.visible"
              :card="card"
            />

            <card-table
              v-if="card.type == 'Table' && card.visible"
              :card="card"
            />
          </b-col>
        </template>
      </draggable>
    </b-row>

    <card-widgets-modal />

    <cards-visibility-modal />

    <confirmation-modal
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-widget'"
      :title="'Are you sure'"
      :message="'Are you sure you want to delete this widget?'"
      @close-modal="cancelWidgetDeleting"
      @confirm="deleteWidget"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'
import { onUnmounted } from '@vue/composition-api'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

import store from '@/store'
import storeModule from '@/views/admin/settings-dashboard/dashboardWidgetsStoreModule'

import CardTotal from '@/views/admin/settings-dashboard/widget-cards/CardTotal.vue'
import CardGoal from '@/views/admin/settings-dashboard/widget-cards/CardGoal.vue'
import CardPie from '@/views/admin/settings-dashboard/widget-cards/CardPie.vue'
import CardTable from '@/views/admin/settings-dashboard/widget-cards/CardTable3.vue'

import CardWidgetsModal from '@/views/admin/settings-dashboard/modals/CardWidgetsModal.vue'
import CardsVisibilityModal from '@/views/admin/settings-dashboard/modals/CardsVisibilityModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,

    draggable,

    TableSpinner,

    CardTotal,
    CardGoal,
    CardPie,
    CardTable,

    CardWidgetsModal,
    CardsVisibilityModal,
    ConfirmationModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-dashboard-widgets'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-dashboard-widgets/getIsLoading',
      confirmationModalToggle: 'app-dashboard-widgets/getConfirmationModalToggle',
    }),
    widgetCards: {
      get() {
        return store.getters['app-dashboard-widgets/getWidgetCards']
      },
      set(val) {
        store.commit('app-dashboard-widgets/SET_WIDGET_CARDS', val)
      },
    },
    currentSchoolName() {
      const school = this.userData.schools.find(item => item.id === this.userData.current_school_id)
      return school.name.toUpperCase()
    },
  },
  async created() {
    await this.setCachedWidgetCards()
  },
  methods: {
    showCardWidgetsModal(mode) {
      store.commit('app-dashboard-widgets/SET_CARD_WIDGETS_MODAL_MODE', mode)
      this.$bvModal.show('card-widgets-modal')
    },
    colsNumber(card) {
      switch (card.type) {
        case 'Goal': return card.widgets.length * 3
        case 'Pie': return card.widgets.length * 3
        case 'Table': return card.widgets[0].settings.cols || 12
        case 'Total': return card.widgets.length * 3
        default: return card.widgets.length * 3
      }
    },
    async finishMove() {
      store.commit('app-dashboard-widgets/UPDATE_CARDS_POSITIONS')
      await store.dispatch('app-dashboard-widgets/saveCardsPositions', this)
    },
    async fetchWidgetCards() {
      await store.dispatch('app-dashboard-widgets/fetchWidgetCards')
    },
    async fetchCachedWidgetCards() {
      await store.dispatch('app-dashboard-widgets/fetchCachedWidgetCards')
    },
    cancelWidgetDeleting() {
      store.commit('app-dashboard-widgets/RESET_WIDGET_FOR_DELETE')
      store.commit('app-dashboard-widgets/SET_CONFIRMATION_TOGGLE', false)
    },
    async deleteWidget() {
      store.commit('app-dashboard-widgets/SET_CONFIRMATION_TOGGLE', false)
      await store.dispatch('app-dashboard-widgets/deleteWidget', this)
    },
    async setCachedWidgetCards() {
      const cards = JSON.parse(localStorage.getItem('widgetCards'))
      if (cards?.expiry) {
        localStorage.removeItem('widgetCards')
        await this.fetchWidgetCards()
        return
      }
      if (cards) {
        store.commit('app-dashboard-widgets/SET_WIDGET_CARDS', cards)
      } else {
        await this.fetchWidgetCards()
      }
    },
  },
}
</script>
